import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
	StyleSheet,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import AutoHeightImage from "../../Components/AutoHeightImageComp";
import MultiSelectComponent from "./MultiSelectComponent";
import PrivacyPolicy from "./PrivacyPolicy";
import { createAmbassadorSignup, crudCreate } from "../../CRUDHELPER";
import SendingFeedback from "../PerformanceReview/SendingFeedback";
import FeedBackSent from "../PerformanceReview/FeedBackSent";
import PersonalDetailsForm from "./PersonalInformation";
import ProfileImageUploader from "./ProfilePicture";

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			sent: false,

			email: "",
			popia: false,
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			brand: props.brand,
		};
	}

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	componentDidMount() {
		// console.log("componentDidMount");
		// let accessToken = sessionStorage.getItem("accessToken");
		// console.log("Approved User", accessToken);
		// if (accessToken !== null) {
		// 	// this.props.history.push("/dashboard/Home");
		// } else {
		// 	sessionStorage.clear();
		// 	this.setState({
		// 		loading: false,
		// 	});
		// }
		// window.addEventListener("resize", this.resize());
	}

	// resize() {
	// 	setTimeout(() => {
	// 		this.setState({
	// 			screenHeight: window.innerHeight,
	// 			screenWidth: window.innerWidth,
	// 		});
	// 	}, 300);
	// }

	componentWillUnmount() {
		// window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		if (this.state.sent) {
			return <FeedBackSent />;
		} else if (this.state.loading) {
			return <SendingFeedback />;
		} else
			return (
				<View
					style={{
						// flex: 1,
						width: this.state.screenWidth,
						// backgroundColor: APP_COLOURS.BG2,
						backgroundColor: APP_COLOURS.BG,
						height: this.state.screenHeight,
						overflow: "hidden",
					}}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						ref='_scrollView'
						onLayout={() => {
							this.refs._scrollView.scrollTo({
								x: 0,
								y: 0,
								animated: true,
							});
						}}
						// onContentSizeChange={() => {
						// 	this.refs._scrollView.scrollTo({
						// 		x: 0,
						// 		y: 0,
						// 		animated: true,
						// 	});
						// }}
					>
						<View
							style={{
								position: "absolute",
								right: 0,
								top: -10,
							}}>
							<AutoHeightImage
								// width={window.innerWidth * 0.5}
								width={200}
								source={require("../../assets/images/bg.png")}
							/>
						</View>

						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								alignItems: "center",
								position: "absolute",
								left: 20,
								top: -10,
							}}>
							<AutoHeightImage
								width={180}
								source={require("../../assets/images/logo.png")}
							/>
						</View>

						{/* <View
							style={{
								flexDirection: "row",
								alignItems: "center",
								// position: "absolute",
								// left: 68,
								// top: 100,
								// opacity: 0.7,
							}}>
							<AutoHeightImage
								width={12}
								source={require("./verified.png")}
							/>
							<Text
								adjustsFontSizeToFit
								style={{
									fontWeight: "500",
									fontFamily: "Gotham",
									color: "#000",
									fontSize: 15,
									marginLeft: 4,
									// opacity: 0.7,
								}}>
								verified
							</Text>
						</View> */}

						<View
							style={{
								flex: 1,
								maxWidth: 450,
								padding: 20,
								marginHorizontal: "auto",
								marginTop: 100,
							}}>
							<Text
								adjustsFontSizeToFit
								style={{
									color: "#000",
									fontSize: 17,
									fontFamily: "Gotham",
									marginBottom: 20,
									marginTop: 100,
								}}>
								Want to become an influencer?
							</Text>

							<Text
								adjustsFontSizeToFit
								style={{
									color: "#000",
									fontSize: 17,
									fontFamily: "Gotham",
									marginBottom: 20,
								}}>
								Provide your details below and be added to our
								list of potential candidates. Your profile will
								be necessary for our team to assess eligibility.
								We look forward to working with you.
							</Text>

							{[
								{
									type: "heading",
									title: "Personal detailes",
								},
								{
									type: "input",
									title: "First name",
									state: "first_name",
									description: "",
								},
								{
									type: "input",
									title: "Last name",
									state: "last_name",
									description: "",
								},
								{
									type: "input",
									title: "Cell number",
									state: "cell",
									description: "",
									keyboardType: "number-pad",
								},
								{
									type: "input",
									title: "Email",
									state: "email",
									description: "",
								},
								{
									type: "heading",
									title: "Location",
								},
								{
									type: "input",
									title: "Country of residency",
									state: "country",
									description: "",
								},
								{
									type: "input",
									title: "City",
									state: "city",
									description: "",
								},
								{
									type: "heading",
									title: "Profile picture",
								},
							].map((d) => this.inputItem(d))}

							<ProfileImageUploader
								onFileChange={(d) => {
									this.setState({
										document: d,
									});
									console.log(d);
								}}
							/>

							<PersonalDetailsForm
								onDetailsChange={(d) => {
									console.log(d);
									this.setState({
										demographic: d,
									});
								}}
							/>

							{[
								{
									type: "heading",
									title: "Instagram",
									description: "",
								},
								{
									type: "input",
									title: "Instagram handle",
									state: "instragram",
									description:
										"Please provide your instragram handle",
									defaultValue: "@",
								},
								{
									type: "input",
									title: "Total Followers",
									state: "instagram_followers",
									description: "",
									keyboardType: "number-pad",
								},
								{
									type: "input",
									title: "Instagram link",
									state: "instagram_link",
									description:
										"Paste a link to instagram content that best describes your niche.",
								},
								{
									type: "heading",
									title: "TikTok",
									description: "",
								},
								{
									type: "input",
									title: "TikTok handle",
									state: "TikTok",
									description:
										"Please provide your TikTok handle",
									defaultValue: "@",
								},
								{
									type: "input",
									title: "Total Followers",
									state: "TikTokfollowers",
									description: "",
									keyboardType: "number-pad",
								},
								{
									type: "input",
									title: "TikTok link",
									state: "tiktok_link",
									description:
										"Paste a link to TikTok content that best describes your niche.",
								},

								{
									type: "heading",
									title: "Facebook",
									description: "",
								},
								{
									type: "input",
									title: "Facebook handle",
									state: "Facebook",
									description:
										"Please provide your Facebook handle",
									defaultValue: "@",
								},
								{
									type: "input",
									title: "Total Followers",
									state: "Facebookfollowers",
									description: "",
									keyboardType: "number-pad",
								},
								{
									type: "input",
									title: "Facebook link",
									state: "facebook_link",
									description:
										"Paste a link to Facebook content that best describes your niche.",
								},
								{
									type: "heading",
									title: "",
									description:
										"Select one or more options you're interested in.",
								},
							].map((d) => this.inputItem(d))}

							<MultiSelectComponent
								onSelectionChange={(d) => {
									this.setState({
										interests: d,
									});
								}}
							/>

							<PrivacyPolicy
								setParentState={(d) => {
									this.setState({
										popia: d,
									});
									console.log(d);
								}}
							/>

							{/* <View
							style={{
								width: "100%",
								padding: 10,

								justifyContent: "center",
								alignItems: "center",
								// maxWidth: 350,
								minWidth: 300,
								marginTop: 15,
								borderWidth: 1,
								borderColor: this.state.emailValid
									? "#E8758F"
									: "#1F2229",
								// backgroundColor: "#1F2229",
								backgroundColor: "#FFF",
								borderRadius: 12,
								flexDirection: "row",
							}}>
							<Image
								source={require("../assets/images/tick.png")}
								style={{
									width: 20,
									height: 20,
									resizeMode: "contain",
									marginRight: 10,
									opacity: this.state.emailValid ? 1 : 0,
								}}
							/>

							<TextInput
								style={{
									width: "100%",
									fontSize: 16,
									textAlign: "left",
									color: APP_COLOURS.BG3,
									fontWeight: "200",
									fontFamily: "Gotham",
									letterSpacing: 0.26,
									padding: 5,
								}}
								placeholderTextColor={"#797979"}
								clearButtonMode='while-editing'
								autoCompleteType='username'
								autoCorrect={false}
								keyboardType='email-address'
								textContentType='emailAddress'
								placeholder={`Email address`}
								value={this.state.emailInput}
								onChangeText={(text) => {
									let validated = this.validateEmail(text);
									this.setState({
										emailInput: text,
									});
								}}
							/>
						</View> */}

							<View
								style={{
									width: "100%",
									// alignItems: "center",
									// justifyContent: "center",
									marginVertical: 50,
								}}>
								{!this.state.loading ? (
									<TouchableOpacity
										style={{
											width: "100%",
										}}
										disabled={
											this.state.email.length < 6 ||
											!this.state.popia
										}
										onPress={() => {
											this.setState({
												loading: true,
											});

											createAmbassadorSignup(
												{ ...this.state },
												(status_) => {
													if (status_ === 200) {
														this.setState({
															sent: true,
														});
													} else {
													}
												},
											);
										}}>
										<View
											style={{
												backgroundColor:
													this.state.email.length <
														6 || !this.state.popia
														? "#D7AAB4"
														: "#E8758F",
												width: "100%",
												padding: 14,
												justifyContent: "center",
												alignItems: "center",
												borderRadius: 10,
											}}>
											{this.state.loading ? (
												<ActivityIndicator />
											) : (
												<Text
													style={{
														color: "#FFF",
														fontWeight: "500",
													}}>
													Submit
												</Text>
											)}
										</View>
									</TouchableOpacity>
								) : (
									<View
										style={{
											backgroundColor: "#D7AAB4",
											padding: 6,
											justifyContent: "center",
											alignItems: "center",
											borderRadius: 15,
											width: 30,
											height: 30,
											marginLeft: "auto",
											marginRight: "auto",
										}}>
										<ActivityIndicator color='#FFF' />
									</View>
								)}
							</View>
						</View>
					</ScrollView>
				</View>
			);
	}

	inputItem = (d) => {
		if (d.type === "input") {
			return (
				<View style={{}}>
					{d.description !== "" ? (
						<Text style={styles.label}>{d.description}</Text>
					) : null}

					<View
						style={{
							width: "100%",
							padding: 10,

							justifyContent: "center",
							alignItems: "center",
							// maxWidth: 350,
							// minWidth: 300,
							marginTop: 15,
							borderWidth: 1,
							borderColor: this.state[d.state]
								? "#FFF"
								: "#1F2229",
							// backgroundColor: "#1F2229",
							backgroundColor: "#FFF",
							borderRadius: 12,
							flexDirection: "row",
						}}>
						<Image
							source={require("../assets/images/tick.png")}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
								marginRight: 10,
								opacity: this.state[d.state] ? 1 : 0,
							}}
						/>

						<TextInput
							style={{
								width: "100%",
								fontSize: 16,
								textAlign: "left",
								color: APP_COLOURS.BG3,
								fontWeight: "200",
								fontFamily: "Gotham",
								letterSpacing: 0.26,
								padding: 5,
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='none'
							clearButtonMode='while-editing'
							autoFocus={true}
							placeholder={d.title}
							keyboardType={
								typeof d.keyboardType !== "undefined"
									? d.keyboardType
									: ""
							}
							defaultValue={
								typeof d.defaultValue !== "undefined"
									? d.defaultValue
									: ""
							}
							value={this.state[d.state]}
							onChangeText={(text) => {
								this.setState({
									[d.state]: text,
								});
							}}
						/>
					</View>
				</View>
			);
		} else if (d.type === "heading") {
			return (
				<View style={{}}>
					<Text style={styles.label}>{d.title}</Text>
					{d.description !== "" ? (
						<Text
							style={{
								padding: 10,
								color: APP_COLOURS.BG3,
								marginTop: 5,
								fontFamily: "Gotham",
							}}>
							{d.description}
						</Text>
					) : null}
				</View>
			);
		}
	};
}

const styles = StyleSheet.create({
	label: {
		fontSize: 19,

		marginTop: 40,
		color: APP_COLOURS.BG3,
		fontWeight: "600",
		fontFamily: "Gotham",
	},
});
