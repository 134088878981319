import React from "react";
import { useParams } from "react-router-dom";
import InfluencerSignupForm from "./index";

const InfluencerSignupWrapper = () => {
	const { brand } = useParams();
	return <InfluencerSignupForm brand={brand} />;
};

export default InfluencerSignupWrapper;
